import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../lib/routes/routes";
import { Container } from "../Container/Container";
import { HeaderTop } from "./components/HeaderTop";
import { LogoLink } from "./components/LogoLink";
import { MobileMenu } from "./components/MobileMenu";

const renderNavItems = () =>
  Object.values(routes).map((route) => (
    <Link
      key={route.to}
      activeClassName={"text-secondary"}
      className={
        "text-primary hover:text-secondary transition duration-200 font-medium"
      }
      to={route.to}
    >
      {route.label}
    </Link>
  ));

export const Header = (props) => {
  return (
    <header className={"flex flex-col"}>
      <HeaderTop className="hidden xl:flex" />
      <nav className="bg-white py-4">
        <Container className="grid grid-cols-12">
          <div className="div col-span-11 xl:col-span-5 2xl:col-span-3">
            <LogoLink />
          </div>
          <div className="hidden 2xl:col-span-7 col-span-7 nav-items xl:flex 2xl:justify-center justify-end space-x-5 text-primary items-center font-medium ">
            {renderNavItems()}
          </div>
          <div className="mm xl:hidden flex justify-end items-center">
            <MobileMenu />
          </div>
        </Container>
      </nav>
    </header>
  );
};
