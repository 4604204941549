import React, { ReactNode } from "react";

export interface ContainerProps {
  className?: string;
  children?: ReactNode | ReactNode[];
}

export const Container = (props: ContainerProps) => {
  const { children, className = "" } = props;
  return (
    <div {...props} className={`container mx-auto px-6 w-5/6 2xl:w-4/6 ${className}`}>{children}</div>
  );
};
