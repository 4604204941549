import React, { ReactNode } from "react";
import { HeaderContactItem } from "../hooks/useHeaderContactItems";

export interface HeaderTopLinkProps extends HeaderContactItem {
  className?: string;
}

export const HeaderTopLink = (props: HeaderTopLinkProps) => {
  const { href, label, icon } = props;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
      <div className="icon text-white">{icon}</div>
      <div className="label">{label}</div>
    </a>
  );
};
