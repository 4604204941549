import React from "react";
import { Container } from "../Container/Container";
import { LogoLink } from "../Header/components/LogoLink";
import { FooterBottom } from "./components/FooterBottom";
import { FooterLink } from "./components/FooterLink";
import { FooterLinksHeading } from "./components/FooterLinksHeading";
import { useContactLinks } from "./hooks/useContactLinks";

export interface FooterProps {
  className?: string;
}

const importantLinks = [
  {
    label: "Zoznam správcov",
    to:
      "https://www.justice.gov.sk/Stranky/Registre/Zoznamy-vedene-MS-SR/Zoznam-spravcov.aspx",
  },
  {
    label: "Formuláre pre konkurz a reštrukturalizáciu",
    to:
      "https://www.justice.gov.sk/Formulare/Stranky/Konkurz-a-restrukturalizacia.aspx",
  },
];

const serviceLinks = [
  {
    label: "Konkurzy",
    to: "/#services",
  },
  {
    label: "Reštrukturalizácie",
    to: "/#services",
  },
  {
    label: "Osobné bankroty",
    to: "/#services",
  },
];

export const Footer = (props: FooterProps) => {
  const contact = useContactLinks();

  return (
    <footer className={`flex flex-col border-t-4 border-secondary`}>
      <Container className="py-12">
        <section className="top grid grid-cols-1 xl:grid-cols-4 gap-10">
          <article className="logolink">
            <LogoLink />
          </article>
          <article className="service-links">
            <FooterLinksHeading>Služby</FooterLinksHeading>
            <div className="links flex flex-col space-y-1">
              {serviceLinks.map((link) => (
                <FooterLink key={link.label} {...link} />
              ))}
            </div>
          </article>
          <article className="important-links">
            <FooterLinksHeading>Dôležité linky</FooterLinksHeading>
            <div className="links flex flex-col space-y-1">
              {importantLinks.map((link) => (
                <FooterLink key={link.label} {...link} />
              ))}
            </div>
          </article>
          <article className="flex flex-col space-y-4">
            <div className="links">
              <FooterLinksHeading>Kontaktujte nás</FooterLinksHeading>
              <div className="clinks flex flex-col space-y-1">
                <a
                  href={`tel:${contact.phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {contact.phone}
                </a>
                <a
                  href={`mailto:${contact.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {contact.email}
                </a>
              </div>
            </div>
          </article>
        </section>
      </Container>
      <FooterBottom />
    </footer>
  );
};
