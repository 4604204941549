import { Link } from "gatsby";
import React from "react";

export interface FooterLinkProps {
  className?: string;
  to: string;
  label: string;
}

export const FooterLink = (props: FooterLinkProps) => {
  const { className = "", to, label } = props;
  return (
    <Link target="_blank" to={to} className={`${className} hover:text-secondary transition duration-200`}>
      {label}
    </Link>
  );
};
