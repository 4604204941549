import { graphql, Link, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import { routes } from "../../../../lib/routes/routes";

export type LogoLinkProps = React.HTMLAttributes<HTMLElement>;

export const LogoLink: React.FC<LogoLinkProps> = (props: LogoLinkProps) => {
  const { file } = useStaticQuery(graphql`
    query Logo {
      file(absolutePath: {}, relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 220, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <Link to={routes.home.to} className={props.className }>
      <GatsbyImage fixed={file.childImageSharp.fixed} />
    </Link>
  );
};
