export interface Route {
  label: string;
  to: string;
}

export const generateMonetizationRoute = (slug: string) => `/spenazovanie/${slug}`

export const routes = {
  home: {
    label: "DOMOV",
    to: "/",
  },
  aboutUs: {
    label: "O NÁS",
    to: "/#about-us",
  },
  services: {
    label: "SLUŽBY",
    to: "/#services",
  },
  news: {
    label: "SPEŇAŽOVANIE",
    to: "/#monetization",
  },
  contact: {
    label: "KONTAKT",
    to: "/#contact",
  },
};
