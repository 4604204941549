import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import { GlobalStyle } from "../Theme/components/GlobalStyle";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

const Layout = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div>
      <Helmet
      title={site.siteMetadata.title}
        meta={[
          { name: "description", content: "Sample" },
          {
            name: "keywords",
            content: "sample, something",
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Header />
      <main className="max-w-screen overflow-hidden">{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
