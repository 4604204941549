import React from "react";

export interface FooterBottomProps {
  className?: string;
}

export const FooterBottom = (props: FooterBottomProps) => {
  const { className = "" } = props;
  return (
    <section className={`${className} bg-primary text-white py-2 text-center`}>
      Všetky práva vyhradené Profesionálna správcovská kancelária, k.s.
    </section>
  );
};
