import { graphql, useStaticQuery } from "gatsby";

export interface ContactLinks {
  phone: string;
  email: string;
}

export const useContactLinks = (): ContactLinks => {
  const { strapiContact } = useStaticQuery(graphql`
    query useContactLinks {
      strapiContact {
        phone
        email
      }
    }
  `);

  return strapiContact;
};
