import React, { ReactNode } from "react";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useContactLinks } from "../../Footer/hooks/useContactLinks";

export interface HeaderContactItem {
  href: string;
  label: string;
  icon: ReactNode;
}

export const useHeaderContactItems = (): HeaderContactItem[] => {
  const contact = useContactLinks();

  return [
    {
      href: `mailto:${contact.email}`,
      label: contact.email,
      icon: <FiMail />,
    },
    {
      href: `tel:${contact.phone}`,
      label: contact.phone,
      icon: <FiPhoneCall />,
    },
  ];
};
