import React, { ReactNode } from "react";

export interface FooterLinksHeadingProps {
  className?: string;
  children: ReactNode;
}

export const FooterLinksHeading = (props: FooterLinksHeadingProps) => {
  const { className = "", children } = props;
  return <h4 className={`${className} font-bold mb-2 text-lg text-primary`}>{children}</h4>;
};
