import React from "react";
import { IoMdRemove } from "react-icons/io";
import { Container } from "../../Container/Container";
import { useHeaderContactItems } from "../hooks/useHeaderContactItems";
import { HeaderTopLink } from "./HeaderTopLink";
import { LogoLink } from "./LogoLink";
import { MobileMenu } from "./MobileMenu";

export interface HeaderTopProps {
  className?: string;
}

export const HeaderTop = (props: HeaderTopProps) => {
  const { className = "" } = props;

  const contactItems = useHeaderContactItems();

  return (
    <div className={`${className} flex justify-end py-2 text-base bg-primary text-white`}>
      <Container className="flex justify-end">
        <div className=" hidden contact-items xl:flex items-center space-x-8">
          {contactItems.map((item) => (
            <HeaderTopLink key={item.href} {...item} />
          ))}
        </div>
      </Container>
    </div>
  );
};
